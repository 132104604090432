<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15 17.0186L15 24.0186C15 24.5708 15.4477 25.0186 16 25.0186C16.5523 25.0186 17 24.5708 17 24.0186L17 17.0186H24C24.5523 17.0186 25 16.5708 25 16.0186C25 15.4663 24.5523 15.0186 24 15.0186H17L17 8.01855C17 7.46627 16.5523 7.01855 16 7.01855C15.4477 7.01855 15 7.46627 15 8.01855L15 15.0186H8C7.44772 15.0186 7 15.4663 7 16.0186C7 16.5708 7.44772 17.0186 8 17.0186H15Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconPlus",
});
</script>
